/* style pagination with tailwind classes */

.pagination {
  @apply flex;
}

.pagination__item {
  @apply relative inline-flex items-center justify-center w-[40px] h-[40px]  text-sm font-medium text-gray-600 hover:bg-gray-50;
}
.pagination__item a {
  @apply px-4 py-2;
}
.pagination__item-previous {
  @apply relative inline-flex items-center  bg-white px-5 py-2 text-sm font-medium text-primary hover:bg-gray-50;
}

.pagination__item-next {
  @apply relative inline-flex items-center  px-2 py-2 text-sm font-medium text-primary hover:bg-gray-50;
}

.active {
  @apply bg-primary border-[2px] w-fit text-white pointer-events-none cursor-not-allowed font-semibold rounded-md;
}
