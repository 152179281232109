@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
:root {
  --color-primary: #e78020;
  --color-secondary: #00984c;
  --color-border: #e7802085;
  --color-light: #e780201e;
}
body {
  font-family: "Lato", sans-serif;
  background-color: #fffefd;
  font-weight: 500;
}

.side::-webkit-scrollbar {
  width: 0.4em;
}
.side::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  width: 2px;
}
.side::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
}
.svg {
  background-color: var(--color-primary);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}
